@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.container {
  display: grid;
  gap: 2rem 2rem;
}

@include media.tablet-up {
  .container {
    grid-template-columns: auto 18rem;
    grid-template-rows: auto;
    row-gap: 2.5rem;
  }

  .form {
    grid-column: 1 / span 2;
  }
}

.product {
  border: 1px solid var(--color-primary);
  border-radius: 3px;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.page-title {
  margin-bottom: 0.5rem;
}
