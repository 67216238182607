@use "sass:color";
@use "stylesheets/shopper/theme";
@use "stylesheets/media";

.container {
  padding: 1rem;
  background: var(--color-greyscale-1);
  color: var(--color-dark-text);
  font-size: 0.8rem;
  display: grid;
  grid-template-areas:
    "contact"
    "links";
  gap: 1rem 1rem;
}

.links {
  grid-area: links;
}

.contact {
  grid-area: contact;
}

.list {
  & > li {
    margin-top: 0.25rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .social-icons {
    margin-bottom: 0.5rem;

    & > li {
      display: inline-block;
      margin-left: 0.25rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@include media.tablet-up {
  .container {
    grid-template-areas: "links  contact";
  }

  .contact {
    text-align: right;
  }
}
