@use "sass:color";
@use "stylesheets/shopper/theme";
@use "stylesheets/shopper/swatches";

.search {
  margin-bottom: 1rem;
}

.option {
  display: flex;
  align-items: center;

  & > label {
    padding-left: 0.5rem;
    flex: 1;
  }
}

.option + .option {
  margin-top: 0.5rem;
}

.hide {
  display: none;
}
