.totals-table {
  width: 100%;

  & > tbody > tr > td {
    padding: 0.5rem 0;

    &:last-child {
      text-align: right;
    }
  }

  .total-line > td {
    font-weight: bold;
  }
}
