$body-font: "Roboto", sans-serif;
$heading-font: "Poppins", sans-serif;
$shadow-grey: rgba(51, 51, 51, 0.5);
$site-header-fixed-height: 3rem;

$flash-success: #6fcf97;
$flash-notice: #f2c94c;
$flash-error: #fa6666;
$error: #EB5757;

$z-indexes: (
  "flash-messages": 900,
  "scroll-to-top": 990,
  "product-filters": 1000,
  "site-header": 1500,
  "dropdown-menu": 1700,
  "sidebar": 1900,
  "modal": 2000
);

%focus {
  outline: 1px solid var(--color-safe-primary-on-light);
}

$container-x-pad: (
  "mobile": 1rem,
  "tablet": 2rem,
  "desktop": 4rem
);

$button-pad: (
  "small-y": 0.3rem,
  "small-x": 0.5rem,
  "medium-y": 0.5rem,
  "medium-x": 0.8rem,
  "large-y": 1rem,
  "large-x": 2.5rem
);
