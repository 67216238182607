@use "stylesheets/shopper/theme";
@use "stylesheets/media";

.try-on-options {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;
  margin-bottom: 3rem;

  @include media.tablet-up {
    grid-template-columns: 1fr 1fr;
  }
}

.centered {
  text-align: center;
  max-width: 40rem;
  margin: 0 auto;
}

.markdown > p {
  line-height: 1.4rem;
}

.cta-body-copy {
  margin-bottom: 1rem;
}
