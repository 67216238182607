@use "stylesheets/shopper/theme";
@use "stylesheets/media";

.container {
  position: relative;
}

.remove {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  padding: 0.75rem;
  border-radius: 100%;
}

.link {
  text-decoration: none;
  color: inherit;
}

.image-link {
  display: block;
  width: 100%;

  @include media.desktop-up {
    width: 90%;
    margin: 0 auto;
    display: block;
  }
}

.info {
  display: flex;
  align-items: center;

  .name {
    flex: 1;
  }
}

.info,
.pricing {
  margin-top: 1rem;
}

.brand {
  font-family: theme.$heading-font;
  font-weight: bold;
  color: var(--color-dark-text);
}

.model-color {
  font-size: 0.8rem;
  color: var(--color-greyscale-7);
}
