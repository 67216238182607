@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.text-content {
  margin-bottom: 1.5rem;
}

.title {
  margin-bottom: 1rem;
}

@include media.tablet-up {
  .container {
    display: flex;
    align-items: center;
  }

  .text-content {
    flex: 1;
    margin-bottom: 0;
  }

  .product {
    max-width: 18rem;
    margin-left: 3rem;
  }
}
