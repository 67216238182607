@use "stylesheets/shopper/theme";
@use "stylesheets/media";

.fields {
  display: grid;
  gap: 2rem 3rem;

  @include media.tablet-up {
    grid-template-columns: 1fr 1fr;
  }
}

.optional {
  font-style: italic;
  color: var(--color-greyscale-6);
}

.fieldset {
  border: none;

  & > legend {
    font-weight: bold;
    margin-bottom: 1rem;

    &.member-legend {
      margin-bottom: 2rem;
      border-bottom: 2px solid var(--color-primary);
      display: block;
      width: 100%;
      max-width: 12rem;
    }
  }

  & + .fieldset {
    margin-top: 2rem;
  }
}

.fieldset-hint {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.checkbox + .checkbox {
  margin-top: 0.5rem;
}

.optional-section + .optional-section {
  margin-top: 1rem;
}

.hide {
  display: none;
}

.insurance-description {
  margin-bottom: 2rem;
  max-width: 80%;
}

.using-vision-plan-fields {
  margin-top: 2rem;
}

.member-radio {
  margin-top: 1rem;
}

.primary-member-fields {
  margin: 2rem 0;
}

.dependent-member-fields {
  margin-top: 2rem;
}

.rx {
  margin-top: 1rem;
}
