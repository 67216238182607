@use "stylesheets/shopper/theme";

.centered {
  text-align: center;
}

.intro {
  margin-bottom: 2rem;
}

.fields {
  margin-bottom: 2rem;
}

.paragraph-with-link-form {
  line-height: 1.4rem;
  margin-top: 1rem;
}
