@use "stylesheets/shopper/theme";
@use "stylesheets/media";

.product {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.variant-image {
  width: 40%;
  max-width: 130px;
  margin-right: 1rem;
}

.brand {
  font-family: theme.$heading-font;
  font-weight: bold;
  color: var(--color-dark-text);
}

.model-color,
.lens {
  font-size: 0.8rem;
  color: var(--color-greyscale-7);
}

.lens {
  margin-top: 0.1rem;
}

@include media.mobile-only {
  .mobile-photo {
    .details {
      display: none;
    }

    .variant-image {
      width: 100%;
      max-width: none;
      margin-right: 0;
    }
  }
}
