@use "stylesheets/shopper/theme";

.label {
  display: block;
  margin-bottom: 0.25rem;
}

.errors {
  color: theme.$error;
  margin-top: 0.25rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    flex: 1;
    margin-bottom: 0;
  }

  :global(.field_with_errors:first-child) {
    flex: 1;
  }
}
