@use "stylesheets/shopper/theme";

.product-title {
  text-align: left;
  margin-bottom: 2rem;
}

.brand {
  font-family: theme.$heading-font;
  font-size: 2rem;
  margin-bottom: 0.25rem;
  font-weight: bold;
  color: var(--color-dark-text);
}

.model {
  font-size: 1.3rem;
  color: var(--color-greyscale-7);
}
