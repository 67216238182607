@use "stylesheets/shopper/theme";
@use "stylesheets/media";

.section {
  margin-top: 3rem;
}

.delivery-options {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem 1rem;
  margin-bottom: 2rem;

  @include media.tablet-up {
    grid-template-columns: 1fr 1fr;
  }
}

.address-fields {
  display: grid;
  gap: 2rem 3rem;

  @include media.tablet-up {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "street1  street1   street1   street2   street2   street2"
      "city     city      state     state     zip     zip";

    .street-1 { grid-area: street1; }
    .street-2 { grid-area: street2; }
    .city { grid-area: city; }
    .state { grid-area: state; }
    .zip { grid-area: zip; }
  }
}

.credit-card-title {
  margin-bottom: 1rem;
}

.credit-card-description {
  margin-bottom: 2rem;
  max-width: 27rem;
}

.credit-card-field {
  border: 1px solid var(--color-greyscale-4);
  border-radius: 3px;
  padding: 0.75rem 0.5rem;
  margin-bottom: 0.5rem;
}

.lock-icon {
  margin-right: 0.25rem;
}

.centered {
  text-align: center;
  max-width: 40rem;
  margin: 0 auto;
}

.footer {
  margin-top: 3rem;
}

.total {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.patient-notes {
  margin-top: 2rem;
}

.hidden {
  display: none;
}
