.options-grid {
  display: grid;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  gap: 0.5rem 0.5rem;

  &.up-2 {
    grid-template-columns: 1fr 1fr;
  }

  &.up-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
