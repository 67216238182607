.active-try-on {
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--color-greyscale-4);
  margin-bottom: 2rem;

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
}

.frame-card {
  margin-top: 1rem;
}

.empty-state {
  max-width: 30rem;
}

.empty-cta {
  margin-top: 2rem;
}
