@use "sass:map";
@use "sass:color";
@use "stylesheets/shopper/theme";
@use "stylesheets/shopper/buttons";

.button {
  @include buttons.base;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  &:focus {
    outline-offset: 2px;
  }
}

.small {
  @include buttons.small;
}

.medium {
  @include buttons.medium;
}

.large {
  @include buttons.large;
}

.unstyled {
  background: none;
  padding: 0;

  &.medium,
  &.large {
    padding: 0;
  }
}

.primary {
  @include buttons.primary;
}

$medium-y: map.get(theme.$button-pad, "medium-y");
$medium-x: map.get(theme.$button-pad, "medium-x");
$large-y: map.get(theme.$button-pad, "large-y");
$large-x: map.get(theme.$button-pad, "large-x");

.secondary {
  $border-width: 2px;

  background: #fff;
  border: $border-width solid var(--color-primary);
  color: var(--color-safe-primary-on-light);

  &.medium {
    padding: calc(#{$medium-y} - #{$border-width}) calc(#{$medium-x} - #{$border-width});
  }

  &.large {
    padding: calc(#{$large-y} - #{$border-width}) calc(#{$large-x} - #{$border-width});
  }

  &:hover {
    background: var(--color-primary-bleached-1);
  }

  &:disabled:hover {
    background: #fff;
  }

  &.success {
    border-color: theme.$flash-success;
    color: theme.$flash-success;

    &:hover {
      background: color.adjust(theme.$flash-success, $lightness: 30%);
    }
  }
}

.tertiary {
  $border-width: 1px;

  background: #fff;
  border: $border-width solid var(--color-greyscale-7);
  color: var(--color-greyscale-7);
  text-transform: none;
  font-weight: normal;

  &.medium {
    padding: calc(#{$medium-y} - #{$border-width}) calc(#{$medium-x} - #{$border-width});
  }

  &.large {
    padding: calc(#{$large-y} - #{$border-width}) calc(#{$large-x} - #{$border-width});
  }

  &:hover {
    background: var(--color-greyscale-1);
  }

  &:disabled:hover {
    background: #fff;
  }

  &.success {
    border-color: theme.$flash-success;
    color: theme.$flash-success;

    &:hover {
      background: color.adjust(theme.$flash-success, $lightness: 30%);
    }
  }
}

.button-to-form {
  display: inline;
}
