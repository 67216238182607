@use "stylesheets/shopper/theme";

.description {
  line-height: 1.5rem;
  margin-bottom: 1rem;

  & > ul {
    list-style: disc;
    margin-left: 1rem;
  }
}

.stats {
  & > dt {
    font-weight: bold;
    display: inline;
  }

  & > dd {
    display: inline;
  }

  & > dd::after {
    display: block;
    content: " ";
    margin-top: 0.5rem;
  }
}
