@use "stylesheets/shopper/theme";

.container {
  padding: 20px;
  text-align: center;
  background: var(--color-primary);
  color: var(--color-safe-on-primary);
}

.welcome {
  font-weight: bold;
}

.switch {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}
