@use "stylesheets/shopper/theme";

@mixin section-title {
  font-family: theme.$heading-font;
  font-weight: bold;
  color: var(--color-dark-text);
  font-size: 1.3rem;
  line-height: 1.8rem;
}

@mixin section-title-underline {
  border-bottom: 2px solid var(--color-primary);
  max-width: 28rem;
}

@mixin section-title-success {
  border-color: theme.$flash-success;
}
