@use "stylesheets/shopper/theme";

.other-message {
  margin-top: 0.5rem;
  border: 3px solid theme.$flash-notice;
  border-radius: 3px;
  padding: 1rem;
}

.price-quote {
  margin-top: 1rem;
}

.hide {
  display: none;
}
