.link,
.link:visited {
  color: inherit;
}

.button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
  text-align: left;
  font-weight: normal;
}

.button-to-form {
  display: inline;
}
