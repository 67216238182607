@use "stylesheets/media";

.grid {
  display: grid;
  grid-template-areas:
    "summary"
    "form";
  row-gap: 1rem;
}

.form {
  grid-area: form;
}

.summary {
  grid-area: summary;
  margin-bottom: 2rem;
}

@include media.mobile-only {
  .hide-summary-on-mobile {
    grid-template-areas: "form";

    .summary {
      display: none;
    }
  }
}

@include media.tablet-up {
  .grid {
    grid-template-areas: "form summary";
    column-gap: 3rem;
    grid-template-columns: auto 35%;
  }

  .summary {
    margin-bottom: 0;
  }
}

@include media.desktop-up {
  .grid {
    column-gap: 10rem;
  }
}
