@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.link {
  text-decoration: none;
  height: 100%;
  color: var(--color-dark-text);
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
  font-family: theme.$heading-font;
  white-space: nowrap;

  &.active {
    font-weight: bold;
    border-bottom: 0.4rem solid var(--color-primary);
    padding-bottom: 0.1rem;
  }

  .icon-left {
    margin-right: 0.4rem;
    display: flex;
  }

  .icon-right {
    margin-left: 0.4rem;
    display: flex;
  }

  @include media.mobile-only {
    &.hide-on-mobile {
      display: none;
    }

    &.icon-right-on-mobile {
      .text {
        display: none;
      }

      .icon-right {
        margin-left: 0;
      }
    }
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;
  }

  .badge {
    position: absolute;
    top: -8px;
    right: -8px;
    border-radius: 10rem;
    background-color: rgb(220 220 220);
    color: var(--color-dark-text);
    display: block;
    width: 1rem;
    line-height: 1rem;
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;

    &.hidden {
      display: none;
    }
  }
}
