@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.container {
  border: 3px solid var(--color-greyscale-4);
  border-radius: 3px;
  padding: 1rem;
  display: grid;
  grid-template:
    "product"
    "measurement-photo-content"
    "price"
    "price-quote-content"
    "in-office-content";
}

.measurement-photo-content {
  grid-area: measurement-photo-content;
  margin-top: 1.5rem;
}

.price-quote-content {
  grid-area: price-quote-content;
  margin-top: 1.5rem;
}

.in-office-content {
  grid-area: in-office-content;
  margin-top: 1.5rem;
}

.price {
  margin-top: 1.5rem;
}

@include media.desktop-up {
  .container {
    grid-template:
      "product  price" max-content
      "content  price" / auto 20rem;
    gap: 1rem 1rem;
  }

  .product {
    grid-area: product;
    padding: 1rem;
  }

  .price {
    grid-area: price;
    border-left: 1px solid var(--color-greyscale-4);
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }

  .measurement-photo-content,
  .price-quote-content,
  .in-office-content {
    grid-area: content;
    border-top: 1px solid var(--color-greyscale-4);
    padding: 1rem 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }
}

.title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subtitle {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.price-cta {
  width: 100%;
  margin-top: 1rem;
}

.button-icon {
  margin-right: 0.5rem;
}
