@use "sass:color";
@use "stylesheets/shopper/theme";

.container {
  padding: 1rem;
  background: var(--color-greyscale-1);
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: 1rem;
}

.name {
  flex: 1;
}

.icon-right {
  margin-left: 0.5rem;
}
