@use "sass:map";
@use "stylesheets/shopper/theme";
@use "stylesheets/shopper/buttons";

.empty-set {
  text-align: center;
  padding: 4rem 0;
}

.scroll-to-top {
  @include buttons.base;
  @include buttons.medium;
  @include buttons.primary;

  /* stylelint-disable no-duplicate-selectors */
  & {
    border-radius: 30px;
    bottom: 1rem;
    font-weight: normal;
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
    z-index: map.get(theme.$z-indexes, "scroll-to-top");
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
  }
  /* stylelint-enable no-duplicate-selectors */
}

.hidden {
  display: none;
}
