.body {
  padding-top: 2rem;
}

.logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.content {
  max-width: 20rem;
  margin: 0 auto;
}
