@use "sass:map";
@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.container {
  margin: 0 auto;
}

.size-large {
  max-width: 1300px;
}

.size-medium {
  max-width: 900px;
}

.size-small {
  max-width: 600px;
}

.size-xsmall {
  max-width: 450px;
}

.pad-y {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include media.tablet-up {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.pad-x {
  padding-left: map.get(theme.$container-x-pad, "mobile");
  padding-right: map.get(theme.$container-x-pad, "mobile");

  @include media.tablet-up {
    padding-left: map.get(theme.$container-x-pad, "tablet");
    padding-right: map.get(theme.$container-x-pad, "tablet");
  }

  @include media.desktop-up {
    padding-left: map.get(theme.$container-x-pad, "desktop");
    padding-right: map.get(theme.$container-x-pad, "desktop");
  }
}
