@use "stylesheets/shopper/theme";

.container {
  .price-line {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;

    .label {
      flex: 1;
    }

    .amount-strike {
      text-decoration: line-through;
      color: var(--color-greyscale-5);
      margin-right: 0.5rem;
    }

    .amount {
      min-width: 4rem;
      text-align: right;
    }
  }

  .total-line {
    border-top: 1px solid var(--color-greyscale-4);
    padding-top: 0.5rem;
    font-weight: bold;
  }
}
