@use "sass:map";
@use "sass:color";
@use "stylesheets/media";
@use "stylesheets/shopper/theme";
@use "stylesheets/shopper/product_filters";

.wrapper {
  z-index: map.get(theme.$z-indexes, "product-filters");
  width: 100%;
  background: #fff;
  position: sticky;
  top: theme.$site-header-fixed-height;

  &.is-sticky {
    .shadow {
      box-shadow: 0 2px 20px 0 rgb(0 0 0 / 15%);
    }
  }
}

.shadow {
  transition: box-shadow 0.15s ease-in-out;
  position: absolute;
  inset: 50% 20px 0;
  z-index: 0;
}

.shadow-wrapper {
  position: relative;
}

.bar-content {
  position: relative;
  z-index: 1;
  background: #fff;
  display: flex;
  padding: 0 0 0 map.get(theme.$container-x-pad, "mobile");

  @include media.tablet-up {
    padding: 0 0 0 map.get(theme.$container-x-pad, "tablet");
  }

  @include media.desktop-up {
    padding: 0 map.get(theme.$container-x-pad, "desktop");
  }
}

.icon-left {
  margin-right: 0.5rem;
}

$featured-filters-y-pad: 1rem;

.featured-filters {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: $featured-filters-y-pad 0;
}

.featured-filters-sticky-left {
  position: sticky;
  left: 0;
  display: flex;
}

.frame-finder-button-text {
  margin-left: 0.5rem;

  @include media.mobile-only {
    display: none;
  }
}

.featured-filters-fade {
  width: 1rem;
  background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0%));
}

.nowrap {
  white-space: nowrap;
}

.filter {
  display: flex;
}

.filter + .filter {
  margin-left: 0.5rem;
}

// Safari has poor support for intrinsic width when using flex, so we can't pad the right edge
// of the scrolling filters on the parent. Work around this by using a spacer element.
.safari-fix-spacer {
  min-width: map.get(theme.$container-x-pad, "mobile");

  @include media.tablet-up {
    min-width: map.get(theme.$container-x-pad, "tablet");
  }

  @include media.desktop-up {
    display: none;
  }
}

.button {
  @extend %button;
}

.button-action {
  @extend %button-action;
}

.combo-button {
  @extend %combo-button;
}

.fieldset {
  border: none;
}

.legend {
  font-weight: bold;
  margin-bottom: 1rem;
}

.checkbox-item {
  display: flex;
  align-items: center;

  & > label {
    padding-left: 0.5rem;
    flex: 1;
  }
}

.checkbox-item + .checkbox-item {
  margin-top: 0.5rem;
}

.submit-button {
  margin-top: 1rem;
  width: 100%;
}

.dropdown-with-sticky-submit {
  padding: 1rem 1rem 0;
}

.sticky-submit-bar {
  position: sticky;
  bottom: 0;
  padding: 1rem 0;
  background: white;

  .submit-button {
    margin: 0;
  }
}

.button-radio {
  & > input[type="radio"] {
    display: none;
  }

  & > input[type="radio"]:checked + .button {
    @extend %active-button;
  }
}

.fit-graphic {
  width: 4.5rem;
  height: auto;
  display: block;
  margin: 0 auto 1rem;
}

.vsp-heart-graphic {
  width: 1rem;
  height: auto;
}
