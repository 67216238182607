.body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden; // For RightSidebar
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}
