.fields {
  margin-bottom: 2rem;
}

.links {
  margin-top: 1rem;
}

.optin {
  margin-top: 1rem;
  max-width: 20rem;
}
