@use "stylesheets/shopper/headings";

.section-title {
  @include headings.section-title;
}

.underline {
  @include headings.section-title-underline;

  &.success {
    @include headings.section-title-success;
  }

  &.underline-full-width {
    max-width: none;
  }
}

.space-below {
  margin-bottom: 2rem;
}
