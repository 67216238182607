.title {
  margin-bottom: 0.5rem;
}

.title-subtext {
  margin-bottom: 2rem;
}

.option + .option {
  margin-top: 0.5rem;
}

.breadcrumbs {
  margin-bottom: 0.5rem;
}
