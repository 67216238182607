@use "stylesheets/shopper/theme";

.inline-form {
  display: flex;
}

.code {
  flex: 1;
  margin-right: 0.5rem;
}

.errors {
  color: theme.$error;
  margin-top: 0.25rem;
}
