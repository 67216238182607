@use "stylesheets/media";

.widget {
  width: 100%;
  height: 690px;
}

@include media.mobile-only {
  .widget {
    height: 100vh;
  }
}
