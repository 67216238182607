.empty {
  margin: 2rem 0;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text {
  margin-top: 2rem;
}
