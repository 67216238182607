@use "sass:map";
@use "stylesheets/shopper/theme";

%active-button {
  background: var(--color-primary-bleached-3);
  border-color: var(--color-primary-bleached-3);
}

%button {
  background: none;
  cursor: pointer;
  border: 1px solid var(--color-greyscale-4);
  display: flex;
  align-items: center;
  padding: calc(map.get(theme.$button-pad, "medium-y") - 1px) 0.5rem;
  border-radius: 3px;
  text-decoration: none;
  font-weight: normal;

  &.active {
    @extend %active-button;
  }

  &:focus {
    outline-offset: 2px;
  }

  &.border-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  &.border-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}

%button-action {
  padding-left: 0.5rem;
}

%combo-button {
  display: flex;

  .button {
    border-right: none;
    padding-right: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .button-action {
    background: none;
    cursor: pointer;
    border: 1px solid var(--color-greyscale-4);
    border-left: none;
    padding: 0 0.5rem 0 0.25rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    &:focus {
      outline-offset: 2px;
    }
  }

  &.active {
    .button,
    .button-action {
      @extend %active-button;
    }
  }
}
