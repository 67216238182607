.container {
  text-align: right;
}

.button {
  padding: 0.5rem;
  display: inline-block;
  align-items: center;
  color: inherit;
  text-decoration: none;
  font-weight: bold;

  .label {
    margin-right: 0.25rem;
  }
}
