.title-icon {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--color-safe-primary-on-light);
}

.title {
  text-align: center;
}

.nearest-icon {
  margin-right: 1rem;
}

.options > li {
  text-align: center;

  & + li {
    margin-top: 1rem;
  }
}

.hide {
  display: none;
}
