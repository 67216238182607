@use "sass:map";
@use "stylesheets/shopper/theme";
@use "stylesheets/media";

.header {
  border-bottom: 2px solid var(--color-primary);
  background-color: #fff;
  display: grid;
  position: relative;
  z-index: map.get(theme.$z-indexes, "site-header");

  &.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.logo-container,
.primary-container,
.secondary-container,
.hamburger-container {
  display: flex;
  height: 100%;
}

.logo-container {
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.primary-container,
.secondary-container,
.hamburger-container {
  align-items: stretch;
}

.logo {
  max-height: 90%;
  width: auto;
}

.secondary-icon {
  color: var(--color-safe-primary-on-light);
}

.mobile-menu-content {
  padding: 1rem 1rem 0;
}

.mobile-menu-patient-picker {
  margin-bottom: 2rem;
}

.mobile-menu-link {
  display: block;
  text-decoration: none;
  margin-bottom: 1rem;
  font-family: theme.$heading-font;
}

.mobile-menu-hr {
  border: none;
  border-bottom: 1px solid var(--color-primary-bleached-1);
  margin: 1rem 0;
}

@include media.mobile-only {
  .wrapper,
  .header {
    height: theme.$site-header-fixed-height * 3;
  }

  .header {
    padding: 0 0.5rem;
    grid-template-columns: 3rem auto 3rem;
    grid-template-rows: 100%;
  }

  .hamburger-container {
    grid-column: 1;
  }

  .logo-container {
    grid-column: 2;
    align-items: center;
  }

  .primary-container {
    display: none;
  }

  .secondary-container {
    grid-column: 3;
    justify-content: flex-end;
  }

  .primary-space-left {
    margin-left: 0.5rem;
  }
}

@include media.tablet-up {
  .wrapper,
  .header {
    height: theme.$site-header-fixed-height * 2;
  }

  .header {
    padding: 0 1rem;
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: 100%;
  }

  .hamburger-container {
    display: none;
  }

  .logo-container {
    grid-column: 1;
    align-items: flex-start;
  }

  .primary-container {
    grid-column: 2;
    justify-content: center;
  }

  .secondary-container {
    grid-column: 3;
    justify-content: flex-end;
  }

  .primary-space-left {
    margin-left: 1rem;
  }
}

@include media.mobile-only {
  .tablet-only { display: none; }
  .desktop-up { display: none; }
}

@include media.mobile-narrow-only {
  .hide-mobile-narrow { display: none; }
}

@include media.tablet-only {
  .mobile-only { display: none; }
  .desktop-up { display: none; }
}

@include media.desktop-up {
  .mobile-only { display: none; }
  .tablet-only { display: none; }
  .tablet-down { display: none; }
}
