@use "stylesheets/shopper/theme";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
}

.success {
  background: theme.$flash-success;
}

.notice {
  background: theme.$flash-notice;
}

.error {
  background: theme.$flash-error;
}

.icon {
  height: max-content;
  margin: 0 0.5rem;
}
