@use "stylesheets/shopper/theme";

.container {
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  border: 1px solid var(--color-greyscale-4);
  border-radius: 3px;
  padding: calc(1rem + 2px);

  &:hover {
    border: 3px solid var(--color-greyscale-4);
    padding: 1rem;
  }

  &:active {
    border: 3px solid var(--color-safe-primary-on-light);
    padding: 1rem;
  }
}
