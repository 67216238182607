@use "stylesheets/media";

.fields-grid {
  display: grid;
  gap: 2rem 3rem;
  margin-bottom: 2rem;

  @include media.tablet-up {
    grid-template-columns: 1fr 1fr;
  }
}

.centered {
  text-align: center;
}

.password-form {
  margin-top: 3rem;
}

.below-title {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}
