@use "sass:color";
@use "stylesheets/shopper/theme";
@use "stylesheets/media";
@use "stylesheets/shopper/swatches";

.link {
  text-decoration: none;
  color: inherit;
}

.card {
  display: none;

  &.current {
    display: block;
  }
}

.image {
  width: 100%;
}

.info {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.75rem;

  .name {
    flex: 1;
  }
}

.brand {
  font-family: theme.$heading-font;
  font-weight: bold;
  color: var(--color-dark-text);
  display: block;
}

.model {
  font-size: 0.8rem;
  color: var(--color-greyscale-7);
  display: block;
}

.strike-price {
  text-decoration: line-through;
  display: inline;
}

.price {
  display: flex;
  align-items: center;

  &.with-vsp-heart {
    text-decoration: line-through;
  }
}

.vsp-heart {
  margin-left: 0.3rem;

  &.animate {
    animation: pop 0.75s linear 0.5s 2;
  }

  @keyframes pop {
    50% { transform: scale(1.6); }
  }
}

$quick-add-size: 2.25rem;

.bottom {
  display: flex;
  align-items: center;

  .left,
  .right {
    width: $quick-add-size;
  }

  .center {
    flex: 1;
  }
}

.swatches {
  margin: 0.5rem 0 0;
  text-align: center;

  @include swatches.swatches;
}

.quick-add {
  width: $quick-add-size;
  height: $quick-add-size;
  margin: 0.5rem 0 0;
  display: none;

  &.current {
    display: flex;
  }
}

.quick-add-icon {
  font-size: 1.4rem;
  border: 1.5px solid var(--color-greyscale-5);
  line-height: 1rem;
  padding: 0 3px 2px;
  border-radius: 2px;
  color: var(--color-greyscale-5);
}
