@use "stylesheets/media";

.label {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0.1rem 1.2rem;

  @include media.desktop-only {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
