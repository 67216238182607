@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.header {
  display: grid;
  gap: 2rem 2rem;
  margin-bottom: 2rem;

  @include media.tablet-up {
    grid-template-columns: auto 18rem;
  }
}

.product {
  border: 1px solid var(--color-primary);
  border-radius: 3px;
  padding: 1rem;
}

.page-title {
  margin-bottom: 0.5rem;
}

.section-description {
  margin: 0.5rem 0 2rem;
}

.selfies {
  display: grid;
  grid-auto-flow: row;
  gap: 1rem 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 2rem;

  @include media.tablet-up {
    grid-template-columns: 1fr 1fr;
  }
}

.selfie {
  position: relative;

  .delete-selfie {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: #fff;
    padding: 0.75rem;
    border-radius: 100%;
    color: theme.$error;
  }

  .rejected {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: theme.$flash-error;
    padding: 0.5rem;
    display: flex;
    align-items: center;

    .rejected-icon {
      margin-right: 0.5rem;
    }
  }
}

.form {
  margin-bottom: 2rem;
}

.examples {
  display: grid;
  gap: 1rem 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 2rem;

  @include media.tablet-up {
    grid-template-columns: 1fr 1fr;
  }

  .example-photo {
    margin-bottom: 0.5rem;
  }
}

.bullets {
  list-style-type: disc;
  margin-left: 1rem;
  margin-bottom: 2rem;

  & > li + li {
    margin-top: 0.25rem;
  }
}

.subheading {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
