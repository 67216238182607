@use "stylesheets/shopper/theme";

.info {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.75rem;
}

.name {
  flex: 1;
}

.brand {
  font-size: 1rem;
}

.model {
  font-size: 0.8rem;
  color: var(--color-greyscale-7);
}

%placeholder {
  display: inline-block;
  vertical-align: middle;
  height: 8px;
}

.brand-placeholder {
  @extend %placeholder;

  width: 100px;
  background: rgb(45 45 43 / 60%);
}

.model-placeholder {
  @extend %placeholder;

  width: 60px;
  background: var(--color-greyscale-5);
}

.price-placeholder {
  @extend %placeholder;

  width: 50px;
  background: rgb(45 45 43 / 60%);
}
