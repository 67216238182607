@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.page-title {
  font-family: theme.$heading-font;
  font-weight: bold;
  color: var(--color-dark-text);
  font-size: 1.6rem;

  @include media.tablet-up {
    font-size: 1.8rem;
  }
}

.space-below {
  margin-bottom: 2rem;
}
