@use "stylesheets/shopper/headings";

.container {
  /* stylelint-disable selector-max-type */
  h2 {
    margin-bottom: 0.5rem;

    @include headings.section-title;
    @include headings.section-title-underline;
    @include headings.section-title-success;
  }

  p {
    line-height: 1.4rem;
  }
  /* stylelint-enable selector-max-type */
}
