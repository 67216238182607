$mobile-narrow: 370px;
$mobile-short: 600px;
$tablet: 768px;
$desktop: 960px;
$widescreen: 1152px;
$fullhd: 1344px;

@mixin mobile-short-only {
  @media screen and (max-width: $tablet - 1px) and (max-height: $mobile-short) {
    @content;
  }
}

@mixin mobile-narrow-only {
  @media screen and (max-width: $mobile-narrow) {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet-up {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin tablet-down {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop-down {
  @media screen and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin desktop-up {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen-up {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin fullhd-up {
  @media screen and (min-width: $fullhd) {
    @content;
  }
}
