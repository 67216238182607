@use "stylesheets/media";

.grid {
  display: grid;
  grid-template:
    "variant-picker"
    "cta"
    "info"
    "photos";
  row-gap: 2rem;

  @include media.tablet-up {
    gap: 3rem 5rem;
    grid-template:
      "variant-picker   cta" max-content
      "info             cta" max-content
      "photos           cta" max-content / auto 17rem;
  }

  @include media.desktop-up {
    column-gap: 13rem;
  }
}

.variant-picker-container {
  grid-area: variant-picker;
  display: grid;
  grid-template-areas:
    "vto"
    "picker";

  @include media.tablet-up {
    text-align: left;
    grid-template-areas:
      "picker"
      "vto";
  }

  .variant-picker {
    grid-area: picker;
  }

  .virtual-try-on {
    grid-area: vto;
  }
}

.product-information-container {
  grid-area: info;
}

.secondary-photos-container {
  grid-area: photos;
}

.variant-cta-container {
  grid-area: cta;
}
