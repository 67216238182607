@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.container {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 5px solid var(--color-primary-bleached-1);
  border-radius: 0.8rem;
  padding: 2rem 1rem;

  @include media.tablet-up {
    flex-direction: row;
    padding: 1rem;
  }
}

.text {
  text-align: center;
  max-width: 60rem;

  @include media.tablet-up {
    flex: 1;
  }
}

.image {
  width: 100%;
  margin-top: 2rem;

  @include media.tablet-up {
    margin-top: 0;
    margin-left: 2rem;
    max-height: 14vw;
    width: auto;
  }

  @include media.widescreen-up {
    max-height: 187px;
  }
}

.headline {
  font-weight: bold;
  font-family: theme.$heading-font;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-safe-primary-on-light);

  @include media.tablet-up {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

.cta {
  margin-top: 1.5rem;
}

