.title {
  text-align: center;
  margin: 2rem 0 1rem;
}

.back {
  margin-bottom: 0.25rem;
}

.back-link {
  text-decoration: none;
}

.logo {
  margin-top: 1rem;
  max-width: 300px;
  max-height: 80px;
}
