@use "stylesheets/shopper/theme";

.label {
  font-weight: normal;
}

.option-card-checkbox {
  position: absolute;
  left: -10000px;
}

.option-card {
  background: var(--color-greyscale-1);
  padding: 1rem;
  cursor: pointer;
  border: 2px solid transparent;
  text-align: center;

  &.compact-x {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &.default_on_grey {
    background: #fff;
  }
}

:global(body.keyboard-navigation-active) {
  .option-card-checkbox:focus + .option-card {
    @extend %focus;

    outline-offset: 2px;
  }
}

.option-card-checkbox:checked + .option-card {
  border-color: var(--color-greyscale-7);
}

.option-label {
  display: block;
  font-size: 0.8rem;
}

.option-hint {
  font-size: 0.8rem;
  color: var(--color-greyscale-7);
}
