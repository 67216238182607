.fields {
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 3rem;

  .span-row {
    grid-column: 1 / span 2;
  }
}

.links {
  margin-top: 1rem;
}
