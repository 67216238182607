@use "stylesheets/shopper/swatches";

.swatch {
  @include swatches.swatch(1.8rem);

  /* stylelint-disable no-duplicate-selectors */
  & {
    margin-bottom: 0.5rem;
  }
  /* stylelint-enable no-duplicate-selectors */
}
