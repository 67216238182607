@use "sass:map";
@use "sass:color";
@use "stylesheets/shopper/theme";
@use "stylesheets/media";

.container {
  text-align: center;
}

.vsp-heart {
  margin-bottom: 2rem;
}

.vsp-heart-icon {
  width: 3rem;
  height: 3rem;
  margin-bottom: 0.5rem;
}

.vsp-heart + .price {
  border-top: 2px solid var(--color-primary);
  padding-top: 2rem;
  margin-top: -1rem;
}

.price {
  margin-bottom: 2rem;
}

.price-value {
  font-family: theme.$heading-font;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.strike-price {
  text-decoration: line-through;
  display: inline;
  font-weight: normal;
}

.price-hint {
  max-width: 15rem;
  margin: 0 auto;
}

.button-subtext {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  text-transform: none;
  font-weight: normal;
}

.button {
  display: block;
  width: 100%;
  margin-top: 0.5rem;

  &.primary {
    margin-bottom: 1.5rem;
  }
}

.hidden {
  display: none;
}

.vto-icon {
  margin-right: 0.5rem;
}

.hide {
  display: none;
}

.stock {
  margin-top: 1.5rem;
}

.stock-additional-info {
  margin-top: 0.75rem;
}
