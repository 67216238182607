.container {
  display: inline-block;
}

.avatar {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}

.small .avatar {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  font-size: 0.8rem;
}

.medium .avatar {
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  font-size: 1.2rem;
}
