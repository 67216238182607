@use "sass:map";

// Note: these colors are duplicated in style_profile/colors_controller.js, any changes need to be reflected there too
$swatch-colors: (
  "black": #000000,
  "brown": #cd853f,
  "clear": #d2d2d2,
  "blue": #4682b4,
  "grey": #808080,
  "green": #2e8b57,
  "gold": #eee8aa,
  "silver": #bdbcb8,
  "red": #ff0000,
  "white": #f2f2f2,
  "yellow": #fff500,
  "orange": #ffa500,
  "pink": #ffc0cb,
  "violet": #d391fa,
);

@mixin color-gradient($color, $border-color: $color) {
  background: $color;
  background: linear-gradient(135deg, rgb(255, 255, 255) 0%, $color 60%);
  border: 1px solid $border-color;
}

$default-size: 1.25rem;
$outline-offset: 5px;
$outline-size: 1px;

@mixin outline($color) {
  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: -$outline-offset;
    left: -$outline-offset;
    right: -$outline-offset;
    bottom: -$outline-offset;
    border: $outline-size solid $color;
    border-radius: 100%;
  }

  &.no-color::after {
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
  }
}

@mixin swatch($size) {
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  width: $size;
  height: $size;
  text-indent: -10000px;
  position: relative;

  &.black { @include color-gradient(map.get($swatch-colors, "black")); }
  &.brown { @include color-gradient(map.get($swatch-colors, "brown")); }
  &.clear { @include color-gradient(map.get($swatch-colors, "clear"), #a8a8a8); }
  &.blue { @include color-gradient(map.get($swatch-colors, "blue")); }
  &.grey { @include color-gradient(map.get($swatch-colors, "grey"), #6c6c6c); }
  &.green { @include color-gradient(map.get($swatch-colors, "green")); }
  &.gold { @include color-gradient(map.get($swatch-colors, "gold"), #c9c278); }
  &.silver { @include color-gradient(map.get($swatch-colors, "silver")); }
  &.red { @include color-gradient(map.get($swatch-colors, "red"), #dc0000); }
  &.white { @include color-gradient(map.get($swatch-colors, "white"), #c1c6cd); }
  &.yellow { @include color-gradient(map.get($swatch-colors, "yellow"), #facc15); }
  &.orange { @include color-gradient(map.get($swatch-colors, "orange"), #ca8a04); }
  &.pink { @include color-gradient(map.get($swatch-colors, "pink"), #ee7c8c); }
  &.violet { @include color-gradient(map.get($swatch-colors, "violet"), #a230ed); }

  &.tortoise {
    background-image: url("images/filters/colors/tortoise.png");
    background-size: cover;
    border: 1px solid #000;
  }

  &.multicolor {
    background: radial-gradient(50% 123.47% at 50% 50%, #00ff94 0%, #720059 100%), linear-gradient(121.28deg, #669600 0%, #f00 100%), linear-gradient(360deg, #0029ff 0%, #8fff00 100%), radial-gradient(100% 164.72% at 100% 100%, #6100ff 0%, #00ff57 100%), radial-gradient(100% 148.07% at 0% 0%, #fff500 0%, #51d500 100%);
    background-blend-mode: screen, color-dodge, overlay, difference, normal;
    border: 1px solid #df3b8a;
  }

  &.no-color {
    background-color: transparent;
    border: 3px solid #000;
  }
}

@mixin swatches() {
  list-style-type: none;

  & > li {
    display: inline-block;
    vertical-align: top;

    .swatch-button {
      display: inline-block;
      border: none;
      background: none;
      padding: 0.5rem;
      cursor: pointer;
      overflow: hidden;
    }

    .swatch {
      @include swatch($default-size);
    }

    &.current .swatch {
      @include outline(var(--color-dark-text));
    }

    &.disabled {
      .swatch {
        @include outline(var(--color-greyscale-5));
      }

      .swatch-button {
        position: relative;
        cursor: not-allowed;

        &::after {
          content: " ";
          display: block;
          // Make the diagonal line a little smaller than the circle so it fits within
          width: calc(#{$default-size} + #{($outline-offset * 2) - ($outline-size * 2)});
          height: $outline-size;
          background: var(--color-greyscale-5);
          position: absolute;
          top: calc(50% + 2px); // Shift a little down to make it appear vertically centered
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
}
