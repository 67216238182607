@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.wrapper {
  background-color: #f1f1f1;
  background-size: cover;
}

.content {
  display: grid;
  padding: 1.5rem 0 1rem;

  &.walk_outs {
    display: block;
  }
}

.headline-container {
  grid-area: headline;
  align-self: center;
}

.headline {
  font-weight: bold;
  font-family: theme.$heading-font;
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.subheadline {
  font-family: theme.$body-font;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.3rem;
  margin-top: 0.5rem;
}

.walk-outs-copy {
  /* stylelint-disable selector-max-type, scss/at-extend-no-missing-placeholder */
  h1 {
    @extend .subheadline;
  }

  ul {
    list-style: disc;
    margin-left: 1rem;
    margin-top: 1rem;

    & > li + li {
      margin-top: 0.5rem;
    }
  }

  p {
    line-height: 1.4rem;
    margin-top: 1rem;
  }
  /* stylelint-enable selector-max-type, scss/at-extend-no-missing-placeholder */
}

.vsp-heart-line {
  border: 0;
  border-bottom: 2px solid var(--color-primary);
  margin: 0 0 1rem;

  @include media.tablet-up {
    max-width: 15rem;
  }
}

.vsp-heart-content {
  display: flex;
}

.vsp-heart-copy {
  flex: 1;
  margin-left: 0.5rem;

  & > h3 {
    font-family: theme.$heading-font;
    font-weight: bold;
  }

  .vsp-heart-cta {
    margin-top: 0.5rem;
  }
}

.photo {
  grid-area: photo;
  box-shadow: -8px 8px 0 0 var(--color-primary);
}

.walk_outs .photo {
  float: right;
  width: 40%;
  margin: 0 0 1rem 1rem;
}

.main-copy {
  grid-area: main-copy;
}

.body-copy {
  /* stylelint-disable selector-max-type */
  p {
    margin-bottom: 1rem;
    line-height: 1.4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  /* stylelint-enable selector-max-type */
}

.value-props {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .value-prop {
    width: 20%;
    text-align: center;
    padding: 0 0.25rem;

    &:first-child { padding-left: 0; }
    &:last-child { padding-right: 0; }
  }

  .value-prop-label {
    display: block;
    font-weight: bold;
    font-size: 0.75rem;
  }

  .icon {
    width: 80%;
    max-width: 5rem;
    margin: 0 auto 0.5rem;
  }

  .icon-path {
    fill: var(--color-dark-text);
  }
}

.body-copy + .value-props {
  margin-top: 1rem;
}

.clearfix {
  clear: both;
}

@include media.mobile-only {
  .content {
    grid-template-areas:
      "headline     photo"
      "main-copy    main-copy";
    grid-template-columns: auto 40%;
    gap: 1rem 1rem;
  }
}

@include media.mobile-narrow-only {
  .value-props {
    display: block;

    .value-prop {
      width: 100%;
      text-align: left;
      padding: 0.1rem 0;
    }

    .icon {
      width: 2rem;
      margin-right: 0.5rem;
    }

    .value-prop-label {
      font-size: 0.85rem;
    }

    .value-prop-label,
    .icon {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

@include media.mobile-short-only {
  .content {
    padding: 0.75rem 0;
    row-gap: 0.75rem;
  }

  .headline {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  .value-props {
    .icon {
      width: 1.4rem;
    }
  }

  .od-logo {
    &.mobile {
      display: none;
    }

    &.mobile-short {
      display: inline-block;
    }
  }
}

@include media.tablet-up {
  .content {
    padding: 1rem 0 2rem;
    grid-template-areas:
      "headline     photo"
      "main-copy    main-copy";
    grid-template-columns: auto 35%;
    gap: 1rem 3rem;
  }

  .headline {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .subheadline {
    font-weight: bold;
    font-family: theme.$heading-font;
    line-height: 1.8rem;
    margin-top: 1rem;
    font-size: 1.3rem;
    max-width: 40rem;
  }

  .photo {
    box-shadow: -12px 12px 0 0 var(--color-primary);
  }

  .walk_outs .photo {
    float: right;
    width: 35%;
    margin: 0 0 2rem 2rem;
  }

  .value-props {
    .value-prop {
      padding: 0 1rem;

      &:first-child { padding-left: 0; }
      &:last-child { padding-right: 0; }
    }

    .value-prop-label {
      font-size: 1rem;
    }
  }

  .od-logo {
    &.mobile {
      display: none;
    }

    &.tablet-up {
      display: inline-block;
    }
  }
}

@include media.desktop-up {
  .content {
    padding: 3rem 0;
    grid-template-areas:
      "headline     photo"
      "main-copy    photo";
    grid-template-columns: auto 35%;
    column-gap: 3rem;
  }

  .subheadline {
    margin-top: 2rem;
  }
}

@include media.widescreen-up {
  .main-copy {
    padding-right: 4rem;
  }
}
