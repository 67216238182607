.option + .option {
  margin-top: 0.5rem;
}

.card-label-text {
  margin-right: 0.5rem;
}

.breadcrumbs {
  margin-bottom: 0.5rem;
}
