@use "stylesheets/media";

.container {
  border: 1px dashed var(--color-primary);
  border-radius: 5px;
}

.opener {
  border: none;
  background: none;
  padding: 1.5rem 2rem;
  font-weight: bold;
  color: var(--color-safe-primary-on-light);
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
}

.icon-left {
  margin-right: 1rem;
}

.hide {
  display: none;
}

.content {
  padding: 1rem;
  position: relative;

  @include media.tablet-up {
    padding: 2rem;
  }
}

.closer {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  background: none;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}
