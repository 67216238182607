.container {
  display: flex;
}

.space-below {
  margin-bottom: 0.5rem;
}

.label {
  flex: 1;
  font-size: 1.3rem;
  font-weight: bold;
}

.aside {
  font-size: 1.3rem;
}
