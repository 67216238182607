@use "stylesheets/shopper/theme";

.container {
  border: 3px solid theme.$flash-success;
  border-radius: 3px;
  padding: 1rem;

  &.space-below {
    margin-bottom: 2rem;
  }
}
