.palette {
  margin-bottom: 1rem;
  display: flex;
  font-size: 0.8rem;
}

.palette-primary {
  width: 150px;
}

.palette-item {
  padding: 20px 5px;
  font-weight: bold;
  text-align: center;
}
