.title {
  max-width: 15rem;
  text-align: center;
  margin: 0 auto;
}

.switch-message {
  margin-bottom: 2rem;
  text-align: center;
}

.current-patient {
  margin: 2rem 0 3rem;
}

.switch-patient {
  margin: 1rem 0;
  display: block;
  width: 100%;
  text-decoration: none;
}
