@use "stylesheets/media";

.container {
  border: 1px solid var(--color-primary);
  border-radius: 3px;
  padding: 1rem;
}

.section + .section {
  margin-top: 2rem;
}

.try-on-title {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.25rem;

  .title-text {
    flex: 1;
  }
}

.switch-kind {
  margin-bottom: 1rem;
}

.products {
  margin-top: 1rem;
  display: grid;
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  gap: 1rem 1rem;
  grid-template-columns: 1fr 1fr 1fr;

  @include media.tablet-up {
    grid-template-columns: 1fr;
  }
}

.stock-disclaimer {
  font-style: italic;
  margin-top: 1rem;
}

.hr {
  border: 0;
  border-top: 2px solid var(--color-primary);
  margin: 2rem 0;

  @include media.mobile-only {
    margin: 1rem 0;
  }
}

.discount-form {
  margin-bottom: 1rem;
}

@include media.mobile-only {
  .section + .section {
    margin-top: 1rem;
  }
}
