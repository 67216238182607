.label {
  font-weight: bold;
}

.medium {
  font-size: 1rem;
  margin-top: 0.2rem;
}

.large {
  font-size: 1.3rem;
}

.space-below {
  margin-bottom: 0.5rem;
}
