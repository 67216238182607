.container {
  display: grid;
  grid-column: 1;
  grid-auto-rows: auto;
  row-gap: 2rem;

  &.max-width {
    max-width: 20rem;
    grid-template-columns: 20rem;
  }

  &.compact {
    row-gap: 1rem;
  }
}

.container + .container {
  margin-top: 2rem;
}
