.container {
  display: inline-block;
  position: relative;
}

.digit {
  display: inline-block;

  &:not(:last-of-type) {
    margin-right: 0.4rem;
  }

  & > input {
    width: 2.75rem;
    height: 3.12rem;
    text-align: center;
  }
}

.spinner {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  .digit {
    opacity: 0.4;
  }

  .spinner {
    display: block;
  }
}
