@use "stylesheets/media";

$grid-gap-mobile: 2rem;
$grid-gap-mobile-short: 1.5rem;
$grid-gap-tablet-up: 4rem;
$padding-ratio-tablet-up: 0.5;

.container {
  padding-top: $grid-gap-mobile;
  padding-bottom: $grid-gap-mobile;

  @include media.mobile-only {
    & > * {
      margin-top: $grid-gap-mobile;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  @include media.mobile-short-only {
    padding-top: 1rem;
    padding-bottom: 1rem;

    & > * {
      margin-top: $grid-gap-mobile-short;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  @include media.tablet-up {
    display: grid;
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
    gap: $grid-gap-tablet-up $grid-gap-tablet-up;
    padding-top: $grid-gap-tablet-up * $padding-ratio-tablet-up;
    padding-bottom: $grid-gap-tablet-up * $padding-ratio-tablet-up;

    &.tablet-2 {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include media.desktop-up {
    column-gap: 6rem;

    &.desktop-2 {
      grid-template-columns: 1fr 1fr;
    }

    &.desktop-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
