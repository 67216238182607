@use "stylesheets/shopper/theme";

.real-checkbox {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.opener {
  cursor: pointer;
  display: block;
}

.opened {
  display: none;
  padding-top: 0.5rem;
}

.real-checkbox:checked + .opener + .opened {
  display: block;
}

.switch-patient {
  margin-top: 0.5rem;
  display: block;
  width: 100%;
  text-decoration: none;
}

:global(body.keyboard-navigation-active) {
  .real-checkbox:focus {
    outline: none;

    & + .opener {
      @extend %focus;
    }
  }
}
