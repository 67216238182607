@use "stylesheets/media";
@use "stylesheets/shopper/swatches";
@use "stylesheets/shopper/theme";

.container {
  text-align: center;
  display: grid;
  grid-template-areas:
    "image"
    "swatches";
  row-gap: 2rem;

  @include media.tablet-up {
    text-align: left;
    grid-template-areas:
      "swatches"
      "image";
    row-gap: 3rem;

    &.vto {
      row-gap: 1rem;
    }
  }
}

.swatches-container {
  grid-area: swatches;
}

.image-container {
  grid-area: image;
}

.current-color {
  font-style: italic;
}

.swatches {
  @include swatches.swatches;
}

.image {
  width: 100%;
  display: none;

  &.current {
    display: block;
  }
}
