@use "stylesheets/shopper/theme";

.field {
  border: 1px solid var(--color-greyscale-4);
  border-radius: 3px;
  padding: 0.5rem;
  width: 100%;
}

.container {
  position: relative;

  :global(.field_with_errors) {
    .field {
      padding-right: 2.5rem;
      border-color: theme.$error;
    }
  }
}

.error-icon {
  color: theme.$error;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}
