.title {
  margin-bottom: 1rem;
}

.tracking-info {
  margin-bottom: 1rem;

  & > span + span {
    margin-left: 2rem;
  }
}

.arrival-notice {
  background-color: var(--color-primary-bleached-1);
  padding: 1rem 1.2rem 1.2rem;
}

.frame-card {
  margin-top: 1rem;
}
