.container {
  border: 1px solid transparent;
}

.unavailable {
  border-color: var(--color-greyscale-3);
  border-radius: 5px;

  & + .unavailable {
    margin-top: 0.5rem;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 1rem;
}

.product {
  flex: 1;
}

.remove-link {
  display: block;
  color: inherit;
  padding: 1rem;
  width: 3rem;
  text-align: center;
}

.trash-icon {
  // Prevent the icon from catching the link's click, or Rails disable-with won't work
  pointer-events: none;
}

.highlight {
  animation-duration: 3s;
  animation-name: highlight;
}

.multi-location-availability {
  background: var(--color-greyscale-1);
  padding: 0.75rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@keyframes highlight {
  from {
    background-color: #fff8d3;
  }

  to {
    background-color: none;
  }
}
