@use "sass:color";
@use "stylesheets/shopper/theme";

.container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.real-radio {
  position: absolute;
  left: -10000px;
}

.label {
  flex: 1;
  display: flex;
  cursor: pointer;
  border: 1px solid var(--color-greyscale-4);
  border-radius: 3px;
  padding: calc(1rem + 2px);
}

.content {
  flex: 1;
  margin-left: 0.5rem;
}

.fake-radio-container {
  padding-top: 0.2rem;
}

.fake-radio-outline {
  display: block;
  border: 1px solid var(--color-greyscale-5);
  border-radius: 100%;
  padding: 3px;
}

.fake-radio {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
}

.disabled {
  .label {
    cursor: auto;
  }

  .content,
  .fake-radio-container {
    opacity: 0.6;
  }
}

.notice {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background: color.adjust(theme.$flash-notice, $lightness: 15%);
  padding: 0.5rem 0.5rem calc(0.5rem + 2px) 0.5rem;
  text-align: center;
}

.with-notice .label {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.real-radio:checked + .label {
  border: 3px solid var(--color-primary);
  padding: 1rem;

  .fake-radio-outline {
    border-color: var(--color-safe-primary-on-light);
  }

  .fake-radio {
    background: var(--color-safe-primary-on-light);
  }
}

.with-notice .real-radio:checked + .label {
  border-bottom: 0;
  padding-bottom: calc(1rem + 2px);
}

.container:not(.disabled) .real-radio:not(:checked) + .label:hover {
  border: 3px solid var(--color-greyscale-4);
  padding: 1rem;
}

.container.with-notice:not(.disabled) .real-radio:not(:checked) + .label:hover {
  border-bottom: 0;
  padding-bottom: calc(1rem + 2px);
}

.with-notice .real-radio:checked + .label + .notice {
  border: 3px solid var(--color-primary);
  border-top: 0;
  padding-bottom: calc(0.5rem - 1px);
}

.container.with-notice:not(.disabled) .real-radio:not(:checked) + .label:hover + .notice {
  border: 3px solid var(--color-greyscale-4);
  border-top: 0;
  padding-bottom: calc(0.5rem - 1px);
}

:global(body.keyboard-navigation-active) {
  .real-radio:focus {
    outline: none;

    & + .label .fake-radio-outline {
      @extend %focus;
    }
  }
}
