.empty-state {
  max-width: 30rem;
  margin-top: 2rem;
}

.empty-text {
  margin-bottom: 1rem;
}

.empty-cta {
  margin-top: 1rem;
}

.line-items + .line-items {
  margin-top: 2rem;
}
