@use "sass:color";
@use "sass:map";
@use "stylesheets/shopper/theme";
@use "stylesheets/media";

.header {
  padding: map.get(theme.$container-x-pad, "tablet");

  @include media.mobile-only {
    padding: map.get(theme.$container-x-pad, "mobile");
  }
}

.tab-bar {
  display: flex;
  margin-top: 1rem;
}

.tab {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--color-greyscale-4);
  background: #fff;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  cursor: pointer;
  color: var(--color-greyscale-7);

  .tab-icon {
    margin-right: 0.5rem;
  }

  &.active {
    border-color: var(--color-greyscale-7);
    color: var(--color-dark-text);
    font-weight: bold;
  }

  &:focus {
    outline-offset: 2px;
  }
}

.tab + .tab {
  margin-left: 0.5rem;
}

.content {
  padding: 0 map.get(theme.$container-x-pad, "tablet");

  @include media.mobile-only {
    padding: 0 map.get(theme.$container-x-pad, "mobile");
    padding-top: map.get(theme.$container-x-pad, "mobile");
  }
}

.legend {
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.fieldset {
  border: none;
}

.fieldset + .fieldset {
  margin-top: 2rem;
}

.label {
  font-weight: normal;
}

.toggle {
  display: flex;
  align-items: center;
}

.toggle + .toggle {
  margin-top: 1rem;
}

.toggle-label {
  flex: 1;
  margin-right: 0.5rem;
}

.checkbox-list {
  background: #fff;
  padding: 1rem;
}

.checkbox-item {
  display: flex;
  align-items: center;

  & > label {
    padding-left: 0.5rem;
    flex: 1;
  }
}

.checkbox-item + .checkbox-item {
  margin-top: 0.5rem;
}

.fieldset + .fit-graphic {
  margin-top: 2rem;
}

.fit-graphic {
  width: 4.5rem;
  height: auto;
  display: block;
  margin: 0 auto 1rem;
}

.actions {
  position: sticky;
  bottom: 0;
  padding: map.get(theme.$container-x-pad, "tablet");
  background: var(--color-greyscale-1);
  text-align: center;

  @include media.mobile-only {
    padding: map.get(theme.$container-x-pad, "mobile");
  }

  &.is-sticky {
    box-shadow: 0 2px 30px 0 rgb(0 0 0 / 15%);
  }
}

.submit-button {
  width: 100%;
}

.hide {
  display: none;
}

.sort-by-label {
  font-size: 0;
}

.clear-all {
  text-decoration: underline;
  margin-bottom: 1rem;
  color: var(--color-greyscale-7);
}
