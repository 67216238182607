@use "sass:map";
@use "sass:color";
@use "stylesheets/shopper/theme";

@mixin base() {
  display: inline-flex;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  border: none;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}

$small-y: map.get(theme.$button-pad, "small-y");
$small-x: map.get(theme.$button-pad, "small-x");
$medium-y: map.get(theme.$button-pad, "medium-y");
$medium-x: map.get(theme.$button-pad, "medium-x");
$large-y: map.get(theme.$button-pad, "large-y");
$large-x: map.get(theme.$button-pad, "large-x");

@mixin small() {
  padding: $small-y $small-x;
  font-size: 0.8rem;
}

@mixin medium() {
  padding: $medium-y $medium-x;
}

@mixin large() {
  padding: $large-y $large-x;
}

@mixin primary() {
  background: var(--color-primary);
  color: var(--color-safe-on-primary);

  &:hover {
    background: var(--color-primary-effect);
  }

  &:disabled:hover {
    background: var(--color-primary);
  }

  &.success {
    background: theme.$flash-success;
    color: #fff;

    &:hover {
      background: color.adjust(theme.$flash-success, $lightness: 5%);
    }

    &:disabled:hover {
      background: theme.$flash-success;
    }
  }
}
