@use "stylesheets/media";

.fields {
  display: grid;
  gap: 2rem 3rem;

  @include media.tablet-up {
    grid-template-columns: 1fr 1fr;
  }
}
