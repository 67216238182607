@use "sass:map";
@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.container {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--color-primary);
  color: var(--color-safe-on-primary);
  padding: 1rem;
  z-index: map.get(theme.$z-indexes, "modal") - 1;

  @include media.tablet-up {
    bottom: 1rem;
    left: 1rem;
    max-width: 13rem;
  }
}

.show {
  display: block;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-safe-on-primary);
}

.content {
  text-align: center;

  & > h2 {
    font-size: 3rem;
    font-weight: bold;
  }

  & > :not(:first-child) {
    margin-top: 0.5rem;
  }
}
