@use "stylesheets/shopper/product_filters";

.filter {
  display: flex;
}

.button {
  @extend %button;
}

.button-action {
  @extend %button-action;
}

.combo-button {
  @extend %combo-button;
}

.nowrap {
  white-space: nowrap;
}
