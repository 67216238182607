@use "stylesheets/shopper/theme";

.centered {
  text-align: center;
}

.success-icon {
  font-size: 4rem;
  color: theme.$flash-success;
  margin: 2rem 0;
}

.intro {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.fields {
  margin-bottom: 2rem;
}

.links {
  margin-top: 1rem;
}

.paragraph-with-link-form {
  line-height: 1.4rem;
  margin-top: 1rem;
}

.admin-hint {
  border: 2px solid var(--color-primary);
  padding: 10px;
  margin-bottom: 2rem;
}

.admin-button {
  margin: 0.5rem 0 1rem;
}
