@use "stylesheets/media";
@use "stylesheets/shopper/theme";

// The Account nav is already inside the hamburger menu on mobile so we hide the sidebar here
.nav {
  display: none;
}

@include media.tablet-up {
  .grid {
    display: grid;
    grid-template-areas: "nav content";
    grid-template-columns: 13rem auto;
    column-gap: 2rem;
  }

  .nav {
    display: block;
    grid-area: nav;
  }

  .nav-links {
    list-style: disc inside;
    margin-top: 2rem;

    & > li {
      margin-top: 1rem;
    }
  }

  .nav-link {
    text-decoration: none;

    &.active {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .content {
    grid-area: content;
  }
}

@include media.desktop-up {
  .grid {
    grid-template-columns: 17rem auto;
    column-gap: 4rem;
  }
}
