.fields {
  margin-bottom: 2rem;
}

.centered {
  text-align: center;
}

.optin {
  margin: 1rem auto 0;
  max-width: 20rem;
  text-align: center;
}
