@use "sass:map";
@use "sass:color";
@use "stylesheets/shopper/theme";
@use "stylesheets/media";

.hide {
  display: none;
}

.overlay {
  background: rgb(0 0 0 / 50%);
  z-index: map.get(theme.$z-indexes, "sidebar");
  position: fixed;
  inset: 0;
}

.sidebar {
  position: fixed;
  z-index: map.get(theme.$z-indexes, "sidebar") + 1;
  top: 0;
  right: 0;
  bottom: 0;
  animation-duration: 0.5s;
  animation-name: slide-in;
  animation-fill-mode: forwards;
  width: 370px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--color-greyscale-1);

  @include media.mobile-only {
    width: 100%;
  }
}

.header {
  background: var(--color-greyscale-1);
}

.content {
  flex: 1;
  overflow: auto;
}

.close {
  position: fixed;
  z-index: map.get(theme.$z-indexes, "sidebar") + 3;
  top: 0;
  right: 0;
  padding: 1.25rem;
  background: none;
  border: none;
  cursor: pointer;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}
