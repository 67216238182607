.container {
  $circle-size: 14px;
  $padding: 4px;
  $width: $circle-size * 2 + $padding * 2;
  $height: $circle-size + $padding * 2;

  .checkbox {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    left: -10000px;
  }

  .control {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: $width;
    height: $height;

    .slider {
      position: absolute;
      cursor: pointer;
      inset: 0;
      background-color: var(--color-greyscale-5);
      transition: background 0.4s;
      border-radius: $height;
      outline-offset: 2px;
    }

    .slider::before {
      position: absolute;
      content: "";
      height: $circle-size;
      width: $circle-size;
      left: $padding;
      bottom: $padding;
      background-color: #fff;
      transition: background 0.4s, transform 0.4s;
      border-radius: 50%;
    }
  }

  .checkbox:checked + .control .slider {
    background-color: var(--color-safe-primary-on-light);
  }

  .checkbox:checked + .control .slider::before {
    transform: translateX($circle-size);
  }

  .checkbox:disabled + .control .slider {
    cursor: default;
  }
}

:global(body.keyboard-navigation-active) {
  .container .checkbox:focus + .control .slider {
    outline: 1px solid var(--color-primary);
  }
}
