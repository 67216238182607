@use "stylesheets/shopper/theme";

.container {
  display: flex; // Fix scaling on scroll issue on Safari
}

.title-container {
  padding: 1rem 0;

  .title {
    font-family: theme.$heading-font;
    font-weight: bold;
    font-size: 1.125rem;
  }

  &:not(.with-location) {
    text-align: center;

    .avatar {
      margin-bottom: 1rem;
    }
  }

  &.with-location {
    display: flex;
    align-items: center;

    .title-patient {
      display: flex;
      align-items: center;
      flex: 1;

      .avatar {
        margin-right: 0.5rem;
      }

      .title-patient-info {
        flex: 1;
      }
    }

    .title-location {
      text-align: right;
      margin-left: 0.5rem;
      max-width: 35%;

      .current-location {
        display: block;
      }
    }
  }
}

.secondary-action {
  font-size: 0.8rem;
  color: var(--color-greyscale-7);
  margin-top: 0.25rem;
}

.border {
  border: none;
  border-bottom: 1px solid var(--color-primary-bleached-1);
}

.content {
  padding: 0.5rem 0;
}

.empty-state {
  width: 65%;
  margin: 1rem auto;
  text-align: center;
}

.footer {
  padding: 0 1.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1.5rem 0 0.5rem;
}

.hidden {
  display: none;
}

.my-cart {
  margin-bottom: 1rem;
}

.secondary-icon {
  color: var(--color-safe-primary-on-light);
}

.auto-submit-message {
  font-size: 0.9rem;
  text-align: center;
  margin: 0 auto 1rem;
  max-width: 17rem;
}
