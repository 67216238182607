@use "sass:map";
@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.container {
  display: inline-block;
}

.label {
  .up-arrow {
    display: none;
  }
}

.content {
  z-index: map.get(theme.$z-indexes, "dropdown-menu");
  background: #fff;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
  overflow-y: auto;
  padding-bottom: 2px; // Fixes a bug where scrollbar is shown even when not needed
}

.content-inner {
  max-width: 100vw;
  position: relative;
}

.a11y-close-label {
  position: absolute;
  background: none;
  border: none;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus {
    left: auto;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    padding: 0.5rem;
  }
}

.open {
  .label {
    background: #fff;
    position: relative;
    z-index: map.get(theme.$z-indexes, "dropdown-menu") + 1;
  }

  &.label-toggles-icon-always {
    .up-arrow {
      display: inline;
    }

    .down-arrow {
      display: none;
    }
  }

  &.label-toggles-icon-mobile-only {
    @include media.mobile-only {
      .up-arrow {
        display: inline;
      }

      .down-arrow {
        display: none;
      }
    }
  }
}

@include media.mobile-only {
  .mobile-overshadow {
    position: static;

    .content {
      /* stylelint-disable declaration-no-important */
      width: 100vw !important;
      /* stylelint-enable declaration-no-important */
      box-shadow: 0 100vh 0 100vh theme.$shadow-grey;
    }
  }
}

.hide {
  display: none;
}
