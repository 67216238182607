@use "stylesheets/shopper/theme";

.pill {
  display: inline-block;
  background: var(--color-greyscale-3);
  color: var(--color-dark-text);
  padding: 0.25rem 0.5rem;
  line-height: 1rem;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: normal;
  font-family: theme.$body-font;
}
