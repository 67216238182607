@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.title {
  margin-bottom: 1rem;
}

.tracking-info {
  margin-top: 0.25rem;
  margin-bottom: 1rem;

  & > span + span {
    margin-left: 2rem;
  }
}

.products {
  display: grid;
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  gap: 1rem 1rem;

  @include media.desktop-up {
    grid-template-columns: 1fr 1fr;
  }
}

.product {
  border: 3px solid var(--color-greyscale-4);
  border-radius: 3px;
  padding: 1rem;
}
