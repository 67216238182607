@use "sass:map";
@use "stylesheets/media";
@use "stylesheets/shopper/theme";

.container {
  display: none;

  &.show {
    display: block;
  }
}

.overlay {
  background: rgb(0 0 0 / 50%);
  z-index: map.get(theme.$z-indexes, "modal");
  position: fixed;
  inset: 0;
}

.modal {
  position: fixed;
  z-index: map.get(theme.$z-indexes, "modal") + 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  max-width: 100%;
  max-height: 90%;
  overflow: auto;
}

.modal-inner {
  position: relative;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem;
  background: none;
  border: none;
  cursor: pointer;
}

.pad .content {
  padding: 2rem;
}

@include media.mobile-only {
  .mobile-full .modal {
    max-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 100%;

    .pad .content {
      padding: 1rem map.get(theme.$container-x-pad, "mobile");
    }
  }

  .mobile-centered .modal {
    width: 90%;
  }
}
