.centered-title-icon {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--color-safe-primary-on-light);
}

.centered-title {
  text-align: center;
}

.location {
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: center;
}

.location + .location {
  border-top: 1px solid var(--color-primary-bleached-1);
  padding-top: 2rem;
  margin-top: 2rem;
}

.info-block {
  flex: 1;
  display: flex;
  align-items: top;
  min-width: 0; // https://css-tricks.com/flexbox-truncated-text/
  margin-right: 1rem;
}

.icon {
  color: var(--color-safe-primary-on-light);
  margin-right: 1rem;
  margin-top: 0.2rem;
  min-width: 0; // https://css-tricks.com/flexbox-truncated-text/
}

.info {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.address {
  font-size: 0.8rem;
  margin-top: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
