@use "~minireset.css/minireset.min";
@use "../media";
@use "theme";

/*
#######################################
############    READ ME    ############
#######################################
If you've come here to add CSS: think twice! We use CSS Modules to scope all
our CSS to our Atomic Design-organized components in app/components/. You
probably want to add or modify a component and its styles over there. Global
CSS inevitably becomes a web developer's worst nightmare, so please do your
best to avoid it and future you will be thankful :)
*/

@font-face {
  font-family: "Roboto";
  font-style: normal;
  src: url("../../fonts/Roboto-Regular.woff2") format("woff2"),
       url("../../fonts/Roboto-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-weight: bold;
  src: url("../../fonts/Roboto-Bold.woff2") format("woff2"),
       url("../../fonts/Roboto-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-weight: normal;
  src: url("../../fonts/Poppins-Regular.woff2") format("woff2"),
       url("../../fonts/Poppins-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-weight: bold;
  src: url("../../fonts/Poppins-Bold.woff2") format("woff2"),
       url("../../fonts/Poppins-Bold.woff") format("woff");
  font-display: swap;
}

html, body {
  height: 100%;
}

body,
input,
textarea,
button,
select {
  font-family: theme.$body-font;
  font-size: 12pt;
  color: var(--color-dark-text);
}

form {
  margin-block-end: 0;
}

*:focus {
  outline: none;
}

body.keyboard-navigation-active {
  input, button, select, textarea, a {
    &:focus {
      @extend %focus;
    }
  }
}

// We are using the "content" property of the "before" pseudo-element of
// body to mantain the breakpoint values between css and javascript in sync.
body::before {
  display: none;

  @include media.mobile-only {
    content: "mobile-only";
  }

  @include media.tablet-up {
    content: "tablet-up";
  }

  @include media.desktop-up {
    content: "desktop-up";
  }
}

.turbo-progress-bar {
  background-color: var(--color-safe-primary-on-light);
}
